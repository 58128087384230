import React, { useEffect, useState } from "react";
import Button, { ErrorButton } from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import axios from "axios";
import { toast } from "react-hot-toast";
import TopNav from "../../Components/Extra/TopNav";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../Components/Extra/Modal";

export default function Edit() {
  document.title = `Morning Courses Dashboard - Edit Member`;
  const { id } = useParams();
  const nav = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const [sessionTypes, setSessionTypes] = useState({});
  const [Member, setMember] = useState({
    member_id: "",
    email: "",
    fullname: "",
    barcode: "",
    class: "",
    grade: "",
    whatsapp: "",
    admission_no: "",
    added_by: "",
  });
  const styles = {
    navItem: `bg-neutral-100 px-8 py-2 font-medium rounded transition-all`,
  };
  useEffect(() => {
    fetchMember();
  }, []);
  async function fetchMember() {
    try {
      let response = await axios.get(`/members/find/${id}`);
      if (response.data.data) {
        setMember(response.data.data);
        let sessions = response.data.sessions || [];
        sessions.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        let SessionTypes = {};
        sessions.forEach((se) => {
          SessionTypes[se.type] = SessionTypes[se.type]
            ? SessionTypes[se.type] + 1
            : 1;
        });
        setSessionTypes(SessionTypes);
        setSessionList(sessions);
      } else {
        toast.error("Member not found", { id: "member-fetch" });
        nav("/members");
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleSubmit(e) {
    try {
      e.preventDefault();
      let dataSet = {
        barcode: e.target.barcode.value,
        email: e.target.email.value,
        admission_no: e.target.admission_no.value,
        fullname: e.target.fullname.value,
        class: e.target.class.value,
        grade: e.target.grade.value,
        whatsapp: e.target.whatsapp.value,
      };
      toast.loading("Updating Member", {
        id: "update_toast",
      });
      let response = await axios.patch(`members/update/${id}`, dataSet);
      toast.success("Member Updated.", {
        id: "update_toast",
      });
    } catch (error) {
      toast.error("Something went wrong", {
        id: "update_toast",
      });
    }
  }
  async function deleteMember() {
    try {
      toast.loading("Deleting Member", {
        id: "del_toast",
      });
      let response = await axios.delete(`members/delete/${id}`);
      toast.success("Member Deleted.", {
        id: "del_toast",
      });
      nav("/members");
    } catch (error) {
      toast.error("Something went wrong", {
        id: "del_toast",
      });
    }
  }
  return (
    <div className="main">
      <div className="flex items-center justify-between">
        <div className="page-title">Edit Member</div>
      </div>{" "}
      <TopNav active="mem" />
      <div className="flex flex-wrap">
        <div className=" w-full md:w-[50%] lg:w-[60%]">
          <div className="mt-6 text-lg font-semibold">Member Details</div>
          <hr className="my-4" />
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Barcode"
                name="barcode"
                defaultValue={Member.barcode}
              />
              <Input label="Email" name="email" defaultValue={Member.email} />
              <Input
                label="Admission No"
                name="admission_no"
                defaultValue={Member.admission_no}
              />
              <Input
                label="Full Name"
                name="fullname"
                defaultValue={Member.fullname}
              />
              <Input label="Grade" name="grade" defaultValue={Member.grade} />
              <Input label="Class" name="class" defaultValue={Member.class} />
              <Input
                label="WhatsApp"
                name="whatsapp"
                defaultValue={Member.whatsapp}
              />
            </div>
            <div className="w-max mt-4 flex gap-4 flex-wrap">
              <Button>Update Member</Button>
              <ErrorButton
                type="button"
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              >
                Delete Member
              </ErrorButton>
            </div>
          </form>
        </div>
        <div className="w-full md:w-[50%] lg:w-[40%] px-4">
          <div className="mt-6 text-lg font-semibold">Attended Sessions</div>
          <hr className="my-4" />
          <div className="flex gap-2">
            <div
              className={`${
                sessionTypes["f-ict"] ? "" : "opacity-40"
              } bg-green-600 px-4 py-1 text-xs rounded-full text-white`}
            >
              Fundamentals Of ICT -{" "}
              {sessionTypes["f-ict"] ? sessionTypes["f-ict"] : "0"}
            </div>
            <div
              className={`${
                sessionTypes["web-dev"] ? "" : "opacity-40"
              } bg-cyan-600 px-4 py-1 text-xs rounded-full text-white`}
            >
              Web Designing -{" "}
              {sessionTypes["web-dev"] ? sessionTypes["web-dev"] : "0"}
            </div>
            <div
              className={`${sessionTypes["video-edit"] ? "" : "opacity-40"}
             bg-pink-600 px-4 py-1 text-xs rounded-full text-white`}
            >
              Video Editing -{" "}
              {sessionTypes["video-edit"] ? sessionTypes["video-edit"] : "0"}
            </div>
            <div
              className={`${
                sessionTypes["ms-office"] ? "" : "opacity-40"
              } bg-orange-600 px-4 py-1 text-xs rounded-full text-white`}
            >
              MS Office -{" "}
              {sessionTypes["ms-office"] ? sessionTypes["ms-office"] : "0"}
            </div>
          </div>
          <div className="mt-4">
            {sessionList.map((sess) => {
              return (
                <div className="flex gap-2 items-center">
                  <div
                    className={`${
                      sess.type === "f-ict"
                        ? "bg-green-600"
                        : sess.type === "web-dev"
                        ? "bg-cyan-600"
                        : sess.type === "video-edit"
                        ? "bg-pink-600"
                        : sess.type === "ms-office"
                        ? "bg-orange-600"
                        : ""
                    } w-3 h-3 rounded-full`}
                  ></div>
                  <div>
                    {sess.topic} -{" "}
                    {`${new Date(sess.date).getFullYear()}/${
                      new Date(sess.date).getMonth() + 1
                    }/${new Date(sess.date).getDate()}`}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        active={deleteModalOpen}
        close={() => {
          setDeleteModalOpen(false);
        }}
        title="Delete Member"
      >
        <div>Are you sure you want to delete this member?</div>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-2 mt-4">
          <Button
            onClick={() => {
              setDeleteModalOpen(false);
            }}
          >
            Close
          </Button>
          <ErrorButton
            onClick={() => {
              deleteMember();
            }}
          >
            Delete
          </ErrorButton>
        </div>
      </Modal>
    </div>
  );
}
